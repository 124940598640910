<template>
  <div class="evaluation">
    <div class="left">
      <el-avatar :src="data.user.member_avatar"></el-avatar>
    </div>
    <div class="right">
      <div class="name">{{ data.user.member_username }}</div>
      <div style="padding-bottom: 10px">
        <el-rate disabled :colors="colors" v-model="data.state"></el-rate>
      </div>
      <div class="guge">
        {{ data.create_time }}{{ data.suk ? " | 规格: " + data.suk : "" }}
      </div>
      <div class="neirong">
        {{ data.content }}
      </div>
      <div class="img-list">
        <div v-for="item in data.images" :key="item" class="item">
          <el-image
            v-if="item != ''"
            style="width: 72px; height: 72px"
            :src="item"
            fit="fit"
          ></el-image>
        </div>
      </div>
      <div style="font-size: 14px; color: rgba(0, 0, 0, 0.4)">
        <svg
          width="14px"
          height="13px"
          viewBox="0 0 14 13"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style="margin-right: 10px"
        >
          <defs></defs>
          <g stroke="none" stroke-width="1" fill-rule="evenodd">
            <g
              id="Product-Ratings-Working"
              transform="translate(-245.000000, -855.000000)"
              fill-rule="nonzero"
            >
              <g transform="translate(155.000000, 92.000000)">
                <g transform="translate(40.000000, 184.000000)">
                  <g transform="translate(0.000000, 326.000000)">
                    <g transform="translate(50.000000, 253.000000)">
                      <g>
                        <path
                          fill="rgba(0, 0, 0, 0.2)"
                          d="M0,12.7272727 L2.54545455,12.7272727 L2.54545455,5.09090909 L0,5.09090909 L0,12.7272727 Z M14,5.72727273 C14,5.02727273 13.4272727,4.45454545 12.7272727,4.45454545 L8.71818182,4.45454545 L9.35454545,1.52727273 L9.35454545,1.33636364 C9.35454545,1.08181818 9.22727273,0.827272727 9.1,0.636363636 L8.4,0 L4.2,4.2 C3.94545455,4.39090909 3.81818182,4.70909091 3.81818182,5.09090909 L3.81818182,11.4545455 C3.81818182,12.1545455 4.39090909,12.7272727 5.09090909,12.7272727 L10.8181818,12.7272727 C11.3272727,12.7272727 11.7727273,12.4090909 11.9636364,11.9636364 L13.8727273,7.44545455 C13.9363636,7.31818182 13.9363636,7.12727273 13.9363636,7 L13.9363636,5.72727273 L14,5.72727273 C14,5.79090909 14,5.72727273 14,5.72727273 Z"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g></svg
        >有帮助 ？
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Evaluation",
  props: ["data"],
  data() {
    return {
      colors: ["#ee4d2d", "#ee4d2d", "#ee4d2d"],
      value1: 4,
      circleUrl: "",
    };
  },
};
</script>
<style lang="less" >
.evaluation {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding-bottom: 10px;
  margin-bottom: 20px;
  display: flex;
  .left {
    padding-left: 20px;
    margin-right: 20px;
  }
  .right {
    .el-rate__icon {
      margin-right: 0;
    }
    .name {
      font-size: 12px;
      padding-bottom: 10px;
    }
    .guge {
      font-size: 12px;
      padding-bottom: 10px;
      color: rgba(0, 0, 0, 0.54);
    }
    .neirong {
      font-size: 14px;
    }
    .img-list {
      display: flex;
      margin-top: 20px;
      .item {
        margin: 0 10px 10px 0;
      }
    }
  }
}
</style>