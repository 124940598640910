<template>
  <div class="CouponsIII">
    <div class="folat-left"></div>
    <div style="font-size: 14px">{{ parseInt(data.discount_amount) }}%折</div>
    <div class="folat-right"></div>
  </div>
</template>
<script>
export default {
  name: "CouponsIII",
  props: ["data"],
  data() {
    return {
      circleUrl: "",
    };
  },
};
</script>
<style lang="less" scoped>
.CouponsIII {
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  background: rgba(208, 1, 27, 0.08);
  padding: 3px 10px;
  border: 0;
  white-space: nowrap;
  color: #ee4d2d;
  margin-right: 8px;
  .folat-left {
    content: "";
    width: 4px;
    height: calc(100% - 5px);
    position: absolute;
    top: 3px;
    left: 0;
    background-image: radial-gradient(#fff 2px, transparent 0);
    background-size: 6px 6px;
    background-position-x: -20px;
  }
  .folat-right {
    content: "";
    width: 4px;
    height: calc(100% - 5px);
    position: absolute;
    top: 3px;
    right: 0;
    background-image: radial-gradient(#fff 2px, transparent 0);
    background-size: 6px 6px;
    background-position-x: -6px;
  }
}
</style>