<template>
  <div class="home-goods_info">
    <div class="main-card">
      <div class="cell" v-if="infoData">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">Shopee</el-breadcrumb-item>
          <el-breadcrumb-item v-for="(item, i) in infoData.breadCrumbs" :key="i"><span class="single-hidden"
              @click="toPush(infoData.breadCrumbs[0].value, item)" :style="{
                cursor: item.value != '' ? 'pointer' : '',
                width: item.name.length >= 20 ? '200px' : '0',
                display: item.name.length >= 20 ? 'block' : '',
              }">{{ item.name }}</span></el-breadcrumb-item>
          <!-- <el-breadcrumb-item>套装与插件</el-breadcrumb-item>
          <el-breadcrumb-item
            >🔥 Plastic Pipettes Dropper 🔥 3ML / 5ML / 10ML Disposable
            Graduated Transfer Liquid Pipettes Ikan Laga Betta
            Guppy</el-breadcrumb-item
          > -->
        </el-breadcrumb>
        <div class="info-box">
          <div class="l-info">
            <video muted ref="video1" @click="openPop" autoplay loop style="width: 450px; height: 450px"
              :src="infoData.goods_video" v-if="infoData.goods_video && imgActive == 0"></video>
            <el-image v-show="!infoData.goods_video || imgActive != 0" style="
                width: 450px;
                height: 450px;
                text-align: center;
                line-height: 200px;
              " :src="img" fit="fit" @click="openPop">
              <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                style="width: 150px; height: 150px; margin-top: 140px">
                <path
                  d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
              </svg></el-image>
            <div class="swp-box">
              <div class="swp-left_btn" @click="swpPrev" v-if="imgList.length > 5">
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="swp-right_btn" @click="swpNext" v-if="imgList.length > 5">
                <i class="el-icon-arrow-right"></i>
              </div>
              <div class="swp-cell" :style="{ transform: `translate(${xNUm}px)` }">
                <el-image v-for="(item, i) in imgList" :key="i" class="swp-item"
                  :class="imgActive == i ? 'swp-item_active' : ''"
                  style="width: 82px; height: 82px; flex-shrink: 0; margin: 5px" :src="item" fit="fit" @click="openPop"
                  @mouseover="yiru(item, i)">{{ item }}</el-image>
              </div>
            </div>
            <div style="
                display: flex;
                align-items: center;
                padding: 20px 20px 20px 30px;
                justify-content: space-between;
              ">
              <div style="display: flex; align-items: center">
                <span>分享:</span>
                <div class="fenx">
                  <div class="fenx-img fenx1"></div>
                  <div class="fenx-img fenx2"></div>
                  <div class="fenx-img fenx3"></div>
                  <div class="fenx-img fenx4"></div>
                </div>
                <el-divider direction="vertical"></el-divider>
              </div>

              <div style="display: flex; align-items: center">
                <svg style="margin-right: 10px; cursor: pointer" width="24" height="20" class="cxDz6S">
                  <path
                    d="M19.469 1.262c-5.284-1.53-7.47 4.142-7.47 4.142S9.815-.269 4.532 1.262C-1.937 3.138.44 13.832 12 19.333c11.559-5.501 13.938-16.195 7.469-18.07z"
                    stroke="#FF424F" stroke-width="1.5" fill="none" fill-rule="evenodd" stroke-linejoin="round"></path>
                </svg>
                <span> 最爱（0）</span>
              </div>
            </div>
          </div>
          <div class="r-info">
            <div class="name">
              {{ infoData.title }}
            </div>
            <div class="fensu">
              <div class="stars">
                <span style="
                    border-bottom: 1px solid;
                    padding-bottom: 2px;
                    color: #ee4d2d;
                  ">{{ infoData.score }}</span>
                <el-rate disabled v-model="infoData.score" :colors="colors">
                </el-rate>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div style="margin: 0 10px">
                <span style="border-bottom: 1px solid" v-if="infoData.shop">{{
                  infoData.comment
                }}</span>
                <span style="font-size: 14px"> Ratings</span>
              </div>
              <el-divider direction="vertical" style="margin: 0 20px"></el-divider>
              <div style="margin: 0 10px">
                {{ infoData.sales }}
                <span style="font-size: 14px">Sold</span>
              </div>
            </div>
            <div class="goods-money">
              <div class="price">
                <span v-if="infoData.discount != 0" style="
                    font-size: 1rem;
                    text-decoration: line-through;
                    color: #929292;
                    margin-right: 10px;
                  ">{{ infoData.orgPrice }}</span>
                <span> {{ infoData.price }}</span>
                <div style="
                    margin-left: 10px;
                    padding: 2px 4px;
                    font-size: 12px;
                    background: #ee4d2d;
                    color: #fff;
                    border-radius: 4px;
                  " v-if="infoData.discount != 0">
                  {{ parseFloat(infoData.discount) }}%折扣
                </div>
              </div>
              <div v-if="infoData.limit_purchase_info" style="
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  margin-top: 4px;
                ">
                <img style="height: 14px; margin-right: 10px" :src="limitImg" alt="" />
                <span>开始于{{
                  infoData.limit_purchase_info.start_time | formatTimeS
                }}</span>
              </div>
            </div>
            <!-- 活动位置 -->

            <!-- 活动位置 -->
            <div class="info-content">
              <div class="yunfei" v-if="infoData.shop_coupon.length != 0">
                <div class="text">商店优惠券</div>
                <div class="zhi">
                  <el-popover placement="bottom" title="商店优惠券" width="426" trigger="hover" v-model="show">
                    <div>
                      <div style="font-size: 14px; color: #929292">
                        使用这些商店优惠券至您的购物车中的商品以节省更多！
                      </div>
                      <div v-if="couponlist && couponlist.length != 0" style="
                          max-height: 400px;
                          padding-top: 20px;
                          padding-left: 20px;
                          overflow-y: scroll;
                        ">
                        <CouponsIV v-for="item in couponlist" :key="item.id" :data="item" :infoData="infoData">
                        </CouponsIV>
                      </div>
                    </div>
                    <div slot="reference">
                      <div style="display: flex; align-items: center">
                        <CouponsIII v-for="item in infoData.shop_coupon" :key="item.id" :data="item"></CouponsIII>
                      </div>
                    </div>
                  </el-popover>
                </div>
              </div>
              <div class="yunfei">
                <div class="text">运费</div>
                <div class="zhi">RM{{ infoData.freight_free }}</div>
              </div>
              <div class="guige" v-if="infoData.spu && infoData.spu[0]">
                <div class="text">{{ infoData.spu[0].name }}</div>
                <div class="zhi">
                  <div v-for="(item, i) in infoData.spu[0].item" :key="i" class="guge-btn" @click="qhGuge(1, i, item)">
                    {{ item }}
                    <div class="tick" v-if="i === guige1">
                      <i class="el-icon-check tick-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="guige" v-if="infoData.spu && infoData.spu[1]">
                <div class="text">{{ infoData.spu[1].name }}</div>
                <div class="zhi">
                  <div v-for="(item, i) in infoData.spu[1].item" :key="i" class="guge-btn" @click="qhGuge(2, i, item)">
                    {{ item }}
                    <div class="tick" v-if="i === guige2">
                      <i class="el-icon-check tick-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="yunfei">
                <div class="text" style="padding-top: 6px">数量</div>
                <div class="zhi">
                  <el-input-number v-model="num" @change="handleChange" :min="1" :max="infoData.stock" label="描述文字"
                    style="width: 114px" size="small"></el-input-number>
                  <span style="margin-left: 16px">还剩{{ infoData.stock }}件</span>
                </div>
              </div>
            </div>
            <div style="padding: 24px 0; display: flex" v-if="infoData.stock != 0">
              <el-button type="primary" style="min-width: 152px; min-height: 48px; display: flex" plain
                @click="handleAddBuyCart">
                <span style="display: flex; align-items: center">
                  <svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0"
                    class="shopee-svg-icon -ttlB- icon-add-to-cart" style="
                      width: 18px;
                      height: 18px;
                      stroke: #ee4d2d;
                      color: #ee4d2d;
                      margin-right: 10px;
                    ">
                    <g>
                      <g>
                        <polyline fill="none" points=".5 .5 2.7 .5 5.2 11 12.4 11 14.5 3.5 3.7 3.5" stroke-linecap="round"
                          stroke-linejoin="round" stroke-miterlimit="10"></polyline>
                        <circle cx="6" cy="13.5" r="1" stroke="none" fill="#ee4d2d"></circle>
                        <circle style="color: #ee4d2d" cx="11.5" cy="13.5" r="1" fill="#ee4d2d" stroke="none"></circle>
                      </g>
                      <line fill="none" stroke-linecap="round" stroke-miterlimit="10" x1="7.5" x2="10.5" y1="7" y2="7">
                      </line>
                      <line fill="none" stroke-linecap="round" stroke-miterlimit="10" x1="9" x2="9" y1="8.5" y2="5.5">
                      </line>
                    </g>
                  </svg>
                  加入购物车
                </span></el-button>
              <el-button @click="toPay" type="primary" style="min-width: 104px; min-height: 48px">
                直接购买</el-button>
            </div>
            <div style="padding: 24px 20px; color: #ee4d2d; font-size: 14px" v-else>
              库存不足,无法购买
            </div>
            <div class="chengnuo">
              <img style="width: 20px; height: 20px; margin-right: 10px"
                src="https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/pdp/83e10a1f95cb083579c089448ef8dd3b.png"
                class="MT7p-T" /><span class="zevbuo">Shopee承诺</span><span>Shopee保障您的交易安全：我们只会在买家确认收到商品后，才会拨款给卖家！</span>
            </div>
          </div>
        </div>

        <div class="store-box">
          <div>
            <el-avatar v-if="infoData.shop" style="min-width: 78px; min-height: 78px; margin-right: 20px"
              :src="infoData.shop.shop_avatar"></el-avatar>
          </div>
          <div style="
              border-right: 1px solid rgba(0, 0, 0, 0.05);
              padding-right: 130px;
              margin-right: 55px;
              min-width: 339px;
            ">
            <div style="font-size: 16px">{{ infoData.shop.shop_name }}</div>
            <div style="
                font-size: 14px;
                color: rgba(0, 0, 0, 0.54);
                margin-bottom: 10px;
              ">
              {{ infoData.shop.online_time }}前上线
            </div>
            <div>
              <el-button plain size="small" icon="el-icon-suitcase-1" @click="ToStore">查看商店</el-button>
            </div>
          </div>
          <div class="biyjq">
            <div>
              <span>
                评价
                <span class="red">{{ infoData.shop.comment }}</span>
              </span>
              <span>
                商品
                <span class="red">{{ infoData.shop.goods_num }}</span>
              </span>
              <span>
                加入时间
                <span class="red">{{ infoData.shop.join_time }}前</span>
              </span>
            </div>
            <!-- <div>
              <span>
                商品
                <span class="red">123</span>
              </span>
              <span>
                回应速度
                <span class="red">几小时内</span>
              </span>
              <span>
                粉丝
                <span class="red">1.8千</span>
              </span>
            </div> -->
          </div>
        </div>
        <div style="display: flex">
          <div class="content-box">
            <div class="goods-guge_miaosu">
              <div class="goods-guge">
                <div class="title">商品规格</div>
                <div class="guge-list">
                  <div class="guge-item" v-for="item in infoData.category_attribute" :key="item.id">
                    <div class="left" v-if="item.value">{{ item.name }}</div>
                    <div class="right" v-if="item.value">
                      {{ item.value }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="goods-miaosu">
                <div class="title">商品描述</div>
              </div>

              <div  class="tyu" style="padding: 15px; word-wrap: break-word" >
              <!-- {{infoData.description}} -->

              <el-input readonly v-model="infoData.description" type="textarea" show-word-limit maxlength="5000" :autosize="{
              minRows: 8
            }" placeholder="请输入"></el-input>
              </div>
            </div>
            <div class="goods-pinjia">
              <div style="font-size: 18px; color: rgba(0, 0, 0, 0.87)">
                商店评价
              </div>
              <div class="pinjia-head">
                <div style="margin-right: 30px; min-width: 135px">
                  <div style="
                      font-size: 18px;
                      color: #ee4d2d;
                      text-align: center;
                      min-width: 105px;
                    ">
                    <span style="font-size: 30px">{{ infoData.score }}</span>/5
                  </div>
                  <div style="display: flex; justify-content: center">
                    <el-rate disabled v-model="infoData.score" :colors="colors"></el-rate>
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                  <div class="pinjia-btn" v-for="(item, i) in pjBtn" :key="i"
                    :class="pinjiaIndex == i ? 'pinjia-btn_active' : ''" @click="handelPinjia(i)">
                    {{ item.label }}
                    <span v-if="i != 0">({{ item.value }})</span>
                  </div>
                </div>
              </div>
              <div class="pinjia-list">
                <div v-if="commentList.list.length != 0">
                  <Evaluation v-for="(item, i) in commentList.list" :key="i" :data="item">
                  </Evaluation>
                  <div style="display: flex; justify-content: right">
                    <el-pagination background layout="prev, pager, next" :total="commentList.total"
                      :current-page="pjPage">
                    </el-pagination>
                  </div>
                </div>
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    padding-top: 30px;
                    height: 400px;
                    text-align: center;
                    line-height: 30px;
                  " v-else>
                  <div>
                    <img style="width: 92; height: 83" :src="pjqx" alt="" />
                    <div>尚无评价</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="same" v-if="infoData.shop_recommend.length != 0">
              <div class="title">同店推介</div>
              <div class="same-list">
                <SameStoreGoods :data="item" v-for="(item, i) in infoData.shop_recommend" :key="i"></SameStoreGoods>
              </div>
            </div>
            <div class="same" v-if="infoData.similar_goods.length != 0">
              <div class="title">相似商品</div>
              <div class="same-list">
                <SameStoreGoods :data="item" v-for="(item, i) in infoData.similar_goods" :key="i"></SameStoreGoods>
              </div>
            </div>
          </div>
          <div>
            <div class="coupons-box" v-if="infoData.recommend_coupon.length != 0">
              <div class="title">商店优惠券</div>
              <div>
                <CouponsV v-for="item in infoData.recommend_coupon" :key="item.id" :data="item"></CouponsV>
              </div>
            </div>
            <div class="hot-box" v-if="infoData.hot_goods && infoData.hot_goods.length != 0">
              <div class="title">商店的热门精选</div>
              <InfoHotGoods :data="item" v-for="(item, i) in infoData.hot_goods" :key="i"></InfoHotGoods>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center; line-height: 300px">
        <img :src="lodingImg" alt="" />
      </div>
    </div>

    <!-- 商品图片预览 -->
    <el-dialog :visible.sync="dialogVisible" :lock-scroll="true" width="1000px" :show-close="false">
      <div class="goods-img_pop">
        <div class="pop-left">
          <el-carousel :autoplay="false" height="636px" indicator-position="none" ref="swp" :initialIndex="imgActive"
            @change="swpChange">
            <el-carousel-item v-for="(item, i) in imgList" :key="i">
              <video ref="video2" muted loop autoplay controls style="width: 636px; height: 636px"
                :src="infoData.goods_video" v-show="infoData.goods_video && imgActives == 0"></video>
              <el-image v-if="!infoData.goods_video || imgActives != 0" style="
                  width: 636px;
                  height: 636px;
                  text-align: center;
                  line-height: 1000px;
                " :src="item" fit="fit">
                <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                  style="width: 150px; height: 150px">
                  <path
                    d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                </svg></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="pop-right">
          <div style="
              font-size: 17px;
              font-weight: 600;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            ">
            {{ infoData.title }}
          </div>
          <div class="img-list">
            <div v-for="(item, i) in imgList" :key="i" class="img-list_item" :class="imgActives == i ? 'img-active' : ''">
              <svg style="
                  width: 32px;
                  height: 32px;
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  z-index: 1;
                " enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="shopee-svg-icon sFD+z6"
                v-if="infoData.goods_video && i == 0">
                <g opacity=".54">
                  <g>
                    <circle cx="7.5" cy="7.5" fill="#040000" r="7.3"></circle>
                    <path
                      d="m7.5.5c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-.5c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5-3.4-7.5-7.5-7.5z"
                      fill="#ffffff"></path>
                  </g>
                </g>
                <path d="m6.1 5.1c0-.2.1-.3.3-.2l3.3 2.3c.2.1.2.3 0 .4l-3.3 2.4c-.2.1-.3.1-.3-.2z" fill="#ffffff"></path>
              </svg>
              <el-image @click="yirus(i)" style="width: 80px; height: 80px" :src="item" fit="fit"></el-image>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import InfoHotGoods from "@/components/goods/InfoHotGoods";
import Evaluation from "@/components/goods/Evaluation";
import SameStoreGoods from "@/components/goods/SameStoreGoods";
import CouponsIII from "@/components/marketing/CouponsIII";
import CouponsIV from "@/components/marketing/CouponsIV";
import CouponsV from "@/components/marketing/CouponsVs";
import {
  goodsDetail,
  addBuyCart,
  getBuyCartLength,
  goodsCommentList,
  getSkuNumber,
} from "@/api/home.js";
import { couponReceive, getShopCouponList, shopState } from "@/api/buy.js";
import { brandList } from "@/api/common.js";
import { getBAuth } from "@/utils/auth.js";
import env from "@/settings/env.js";
// import { Editor } from "@wangeditor/editor-for-vue";
export default {
  components: {
    InfoHotGoods,
    Evaluation,
    SameStoreGoods,
    CouponsIII,
    CouponsIV,
    CouponsV,
    // Editor,
  },
  data() {
    return {
      baseUrl: env.fileUrlPrefix,
      pjqx: require("@/assets/img/home/pinjiaqx.png"),
      limitImg: require("@/assets/img/home/limit.png"),
      xNUm: -5,
      img: "",
      imgActive: 0,
      imgActives: 0,
      infoData: "",
      pjBtn: [
        {
          label: "全部",
        },
        {
          label: "5星",
          value: "123",
        },
        {
          label: "4星",
          value: "123",
        },
        {
          label: "3星",
          value: "123",
        },
        {
          label: "2星",
          value: "123",
        },
        {
          label: "1星",
          value: "123",
        },
        // {
        //   label: "附上评论",
        //   value: "0",
        // },
        // {
        //   label: "附有媒体",
        //   value: "0",
        // },
      ],
      imgList: [
        // "https://cf.shopee.com.my/file/a2d05c2bf9d1d2fe3089fe78ac5bf91a",
        // "https://cf.shopee.com.my/file/88dcf564ebeef4765d0bb6ffcd88ed94",
        // "https://cf.shopee.com.my/file/669b4dbb243adbf592742090ff1e0346",
        // "https://cf.shopee.com.my/file/ffabd208108bc0c5ed29549a91cc18dc",
        // "https://cf.shopee.com.my/file/06c31124684755cb30459a023a210d43",
        // "https://cf.shopee.com.my/file/9ca401f56ae66eabe746892b054d5970_tn",
        // "https://cf.shopee.com.my/file/292e37a081b4e026dceefb395f085144",
        // "https://cf.shopee.com.my/file/dbd78912b6f628de1a3a36f1a0ed42ea",
      ],
      dialogVisible: false,
      value2: 5,
      colors: ["#ee4d2d", "#ee4d2d", "#ee4d2d"],

      num: 0,
      guige1: "",
      guige2: "",
      gugeName: ["", ""],
      lodingImg: require("@/assets/img/app/http.gif"),
      pinjiaIndex: 0,
      sku_id: "",
      ppList: [],
      commentList: "",
      pjPage: 1,
      pjPageSize: 10,
      show: false,
      coupon_id: 0, //优惠券id
      discount_id: 0,
      discount_type: 0,
      suit_id: 0,
      couponlist: [],
      editor: null,
      editorConfig: {
        // 富文本菜单栏配置
        MENU_CONF: {},
        readOnly: true,
      },
      mode: "default",
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getBrandList();
    this.getPj();
    this.getGoodsInfo();
  },
  mounted() { },
  destroyed() {
    sessionStorage.removeItem("CHATINFO");
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    // 获取商品详情
    getGoodsInfo() {
      let id = this.$route.query.goodsId;
      let school_id = this.$route.query.school_id;
      goodsDetail({
        goods_id: id,
        school_id: school_id,
      }).then((res) => {
        if (res.code == 1) {
          this.infoData = res.data;
          // 创建聊天对象
          sessionStorage.CHATINFO = JSON.stringify({
            shop_id: this.infoData.shop.id,
            shop_name: this.infoData.shop.shop_name,
            shop_avatar: this.infoData.shop.shop_avatar,
            m_id: 0,
          });
          this.suit_id = res.data.suit_id;
          this.infoData.score = Number(this.infoData.score.toFixed(1));
          // 封面轮播图
          this.imgList.unshift(this.infoData.image);
          if (this.infoData.goods_video_cover) {
            this.imgList.unshift(this.infoData.goods_video_cover);
          }

          this.img = this.infoData.image;
          if (this.infoData.carousel_image) {
            this.infoData.carousel_image.split(",").forEach((item) => {
              this.imgList.push(item);
            });
          }
          //  面包屑
          this.infoData.breadCrumbs = [];
          this.infoData.category_name.split(">").forEach((item, index) => {
            item = item.replace(" ", "");

            this.infoData.breadCrumbs.push({
              name: item,
              value: "",
            });
            this.infoData.category_path_id.split(",").forEach((val, i) => {
              if (index == i) {
                this.infoData.breadCrumbs[index].value = val;
              }
            });
          });
          this.infoData.breadCrumbs.push({
            name: this.infoData.title,
            value: "",
          });

          // 上线时间
          this.infoData.shop.online_time = this.difference(
            this.infoData.shop.online_time * 1000,
            new Date().getTime()
          );
          // 加入时间
          this.infoData.shop.join_time = this.difference(
            this.infoData.shop.join_time * 1000,
            new Date().getTime()
          );
          // 商品评价
          this.pjBtn[1].value = this.infoData.five_star;
          this.pjBtn[2].value = this.infoData.four_star;
          this.pjBtn[3].value = this.infoData.three_star;
          this.pjBtn[4].value = this.infoData.two_star;
          this.pjBtn[5].value = this.infoData.one_star;
          // 规格选项
          if (this.infoData.spu[0]) {
            this.infoData.spu[0].item = this.infoData.spu[0].item.split(",");
            this.infoData.spu[0].item_img = this.infoData.spu[0].item_img
              ? this.infoData.spu[0].item_img.split(",")
              : "";
          }
          if (this.infoData.spu[1]) {
            this.infoData.spu[1].item = this.infoData.spu[1].item.split(",");
            this.infoData.spu[1].item_img = this.infoData.spu[1].item_img
              ? this.infoData.spu[1].item_img.split(",")
              : "";
          }
          //初始化价格
          if (this.infoData.discount !== 0) {
            // 缓存折扣比例
            this.infoData.cacheDiscount = this.infoData.discount;
            let str = this.infoData.price.split("-");
            for (let i = 0; i < str.length; i++) {
              str[i] = str[i].trim();
              str[i] = parseFloat(str[i].substring(2));
            }

            this.infoData.orgPrice = "";
            this.infoData.price = "";
            str.forEach((item, i) => {
              this.infoData.orgPrice =
                this.infoData.orgPrice +
                "RM" +
                item +
                (i == 0 && str.length > 1 ? "-" : "");
              this.infoData.price =
                this.infoData.price +
                "RM" +
                ((item * parseFloat(this.infoData.discount)) / 100).toFixed(2) +
                (i == 0 && str.length > 1 ? "-" : "");
            });
          }

          // this.infoData.price;

          // 品牌
          //  this.infoData.category_attribute.forEach((item) => {
          //   if (item.name == "品牌") {
          //    this.ppList.forEach((val) => {
          //      if (val.id == item.value) {
          //        item.value = val.name;
          //      }
          //    });
          //  }
          //   });

          // 折扣活动
          if (this.infoData.sku_list.length == 0) {
            this.discount_id = this.infoData.discount_info.discount_id;
            this.discount_type = this.infoData.discount_info.discount_type;
          }
          // 推荐优惠券
          if (
            this.infoData.recommend_coupon &&
            this.infoData.recommend_coupon.length != 0
          ) {
            this.infoData.recommend_coupon.forEach((item) => {
              item.state = 1;
            });
          }

          this.getCouponList(res.data.shop.id);
        }
      });
    },
    getBrandList() {
      brandList().then((res) => {
        if (res.code == 1) {
          this.ppList = res.data;
        }
      });
    },
    // 获取店铺优惠券列表
    getCouponList(id) {
      getShopCouponList({
        shop_id: id,
      }).then((res) => {
        if (res.code == 1) {
          this.couponlist = res.data;
        }
      });
    },
    getPj() {
      let id = this.$route.query.goodsId;
      goodsCommentList({
        goods_id: id,
        star: this.pinjiaIndex,
        page: this.pjPage,
        page_size: this.pjPageSize,
      }).then((res) => {
        if (res.code == 1) {
          this.commentList = res.data;
          this.commentList.list.forEach((item) => {
            item.images = item.images.split(",");
            if (item.switch == 0) {
              item.user.member_username =
                item.user.member_username[0] +
                "****" +
                item.user.member_username[item.user.member_username.length - 1];
            }
          });
        }
      });
    },
    yiru(value, index) {
      this.img = value;
      this.imgActive = index;
      this.imgActives = index;
    },
    yirus(index) {
      this.imgActives = index;
      this.$refs.swp.setActiveItem(index);
    },
    swpPrev() {
      if (this.xNUm != -5) {
        this.xNUm = this.xNUm + 93;
      }
    },
    swpNext() {
      if (this.xNUm != (this.imgList.length - 5) * -93 + -5) {
        this.xNUm = this.xNUm + -93;
      }
    },
    openPop() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.swp.setActiveItem(this.imgActive);
      });
    },
    handleChange() { },
    swpChange(index) {
      this.imgActives = index;
    },
    // 规格切换
    qhGuge(type, index, value) {
      this.num = 1;
      // 第一规格
      if (type == 1) {
        this.gugeName[0] = value;
        this.guige1 = index;
        // 规格图片
        if (this.infoData.spu[0].item_img[index]) {
          this.img = this.infoData.spu[0].item_img[index];
        }
      }
      // 第二规格
      if (type == 2) {
        this.gugeName[1] = value;
        this.guige2 = index;
      }

      this.infoData.sku_list.forEach((item) => {
        if (item.discount_info) {
          this.discount_id = item.discount_info.discount_id;
          this.discount_type = item.discount_info.discount_type;
        }

        if (this.gugeName[1] != "") {
          // 存在规格2的处理
          if (this.gugeName.join(",") == item.difference) {
            if (item.discount_info) {
              this.infoData.price = "RM" + item.discount_info.discount_price;
              this.infoData.orgPrice = "RM" + item.price;
              this.infoData.discount = this.infoData.cacheDiscount;
            } else {
              this.infoData.price = "RM" + item.price;
              this.infoData.discount = 0;
            }

            if (item.discount_info) {
              this.infoData.discount = item.discount_info.discount_ratio;
            }

            this.sku_id = item.id;
          }
        } else {
          // 规格1的处理
          if (this.gugeName[0] == item.difference) {
            if (item.discount_info) {
              this.infoData.price = "RM" + item.discount_info.discount_price;
              this.infoData.orgPrice = "RM" + item.price;
              this.infoData.discount = this.infoData.cacheDiscount;
            } else {
              this.infoData.price = "RM" + item.price;
              this.infoData.discount = 0;
            }
            if (item.discount_info) {
              this.infoData.discount = item.discount_info.discount_ratio;
            }

            this.sku_id = item.id;
          }
        }
      });

      getSkuNumber({
        goods_id: this.infoData.id,
        shop_id: this.infoData.shop_id,
        sku:
          this.gugeName[0] +
          (this.gugeName[0] && this.gugeName[1] ? "," : "") +
          this.gugeName[1],
      }).then((res) => {
        if (res.code == 1) {
          this.infoData.stock = res.data;
        }
      });
    },
    difference: function (beginTime, endTime) {
      var dateDiff = endTime - beginTime; //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      // var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      // var seconds = Math.round(leave3 / 1000);

      let date =
        dayDiff != 0
          ? dayDiff + "天"
          : "" + hours != 0
            ? hours + "小时"
            : "" + minutes + "分钟";
      return date;
    },
    handelPinjia(index) {
      this.pinjiaIndex = index;
      this.getPj();
    },
    toPush(value, item) {
      let arr = "";
      let index = "";
      if (item.value != "") {
        if (value != item.value) {
          sessionStorage.CATEGORYID = value;
          this.$store.state.homeFootData.forEach((val) => {
            if (val.category_id == value) {
              arr = val;
            }
          });

          arr.list.forEach((val, i) => {
            if (item.value == val.category_id) {
              index = i;
            }
            if (val.list && val.list.length != 0) {
              val.list.forEach((vals, is) => {
                if (item.value == vals.category_id) {
                  index = arr.list.length + is;
                }
              });
            }
          });
        }
        this.$router.push({ path: "goodsSearchs", query: { index: index } });
      }
    },
    handleAddBuyCart() {
      shopState({
        shop_id: this.infoData.shop_id,
      }).then((res) => {
        if (res.code == 1) {
          if (res.data == 0) {
            if (this.infoData.spu.length != 0) {
              if (this.sku_id == "") {
                this.$message.error("请选择商品规格");
                return false;
              }
            }
            addBuyCart({
              lists: [
                {
                  goods_id: this.infoData.id,
                  sku_id: this.sku_id,
                  num: this.num,
                  coupon_id: this.coupon_id,
                  discount_id: this.discount_id,
                  discount_type: this.discount_type,
                  suit_id: this.suit_id,
                },
              ],
            }).then((res) => {
              if (res.code == 1) {
                this.getCartNum();
                this.$message.success(res.message);
              }
              if (res.code == -1) {
                this.$router.push("buyLogin");
                this.$store.state.buyLoginType = 2;
              }
            });
          } else {
            this.$message.error("该店铺休假中");
          }
        }
      });
    },
    getCartNum() {
      getBuyCartLength().then((res) => {
        if (res.code == 1) {
          this.$store.state.buyCartLength = res.data.cart_num;
        }
      });
    },
    ToStore() {
      window.scrollTo(0, 0);
      this.$router.push({
        path: "storeHome",
        query: {
          shop_id: this.infoData.shop_id,
          // addTime: this.infoData.shop.join_time,
          // onlineTime: this.infoData.shop.online_time,
        },
      });
    },
    toGoodsInfo(id) {
      this.$router.replace({ path: "goodsInfo", query: { goodsId: id } });
      location.reload();
    },
    toPay() {
      shopState({
        shop_id: this.infoData.shop_id,
      }).then((res) => {
        if (res.code == 1) {
          if (res.data == 0) {
            if (!getBAuth()) {
              this.$router.push("buyLogin");
              this.$store.state.buyLoginType = 2;
              return false;
            }
            if (this.infoData.spu.length != 0) {
              if (this.sku_id == "") {
                this.$message.error("请选择商品规格");
                return false;
              }
            }
            sessionStorage.PAYGOODSLIST = JSON.stringify([
              {
                goods_id: this.infoData.id,
                number: this.num,
                sku_id: this.sku_id,
                coupon_id: this.coupon_id,
                discount_id: this.discount_id,
                discount_type: this.discount_type,
                suit_id: this.suit_id,
              },
            ]);

            this.$router.push({ path: "buyPay", query: { type: 2 } });
          } else {
            this.$message.error("该店铺休假中");
          }
        }
      });
    },
    closeShow(id) {
      this.show = false;
      this.coupon_id = id;
    },
    // 领取优惠券
    receive(data) {
      couponReceive({
        type: 1,
        keyword: data.id,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
        }
      });
    },
  },
};
</script>
<style lang="less">
.home-goods_info {
  background: #f5f5f5;
  .el-textarea{
    textarea{
      border: none;
      resize: none;
    }
  }
  .cell {
    padding: 20px 0;

    .info-box {
      display: flex;
      background: #fff;
      padding: 14px;
      margin-top: 20px;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(220, 220, 220, 0.5);
      display: flex;

      .l-info {
        width: 460px;

        .swp-box {
          width: 450px;
          overflow: hidden;
          position: relative;
          color: #fff;
          font-size: 24px;
          cursor: pointer;

          .swp-left_btn {
            position: absolute;
            left: 0;
            top: 30px;
            z-index: 3;
          }

          .swp-right_btn {
            position: absolute;
            top: 30px;
            right: 0;
            z-index: 3;
          }

          .swp-cell {
            // width: auto;
            // flex-shrink: 1;
            display: flex;

            .swp-item {
              border: 2px solid #fff;
            }

            .swp-item_active {
              border-color: #ee4d2d;
            }

            .swp-item:hover {
              border-color: #ee4d2d;
            }
          }
        }

        .fenx {
          display: flex;
          align-items: center;
          margin-right: 30px;
          cursor: pointer;

          .fenx-img {
            background-image: url(../../assets/img/home/wxlog.png);
            background-size: 100%;
            background-color: transparent;
            width: 25px;
            height: 25px;
            margin: 0 5px;
          }

          .fenx1 {
            background-position: 0 -100%;
          }

          .fenx2 {
            background-position: 0 0;
          }

          .fenx3 {
            background-position: 0 -300%;
          }

          .fenx4 {
            background-position: 0 -400%;
          }
        }
      }

      .r-info {
        margin-left: 35px;

        .name {
          font-size: 18px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .el-button--primary.is-plain:focus,
        .el-button--primary.is-plain:hover {
          background: rgba(255, 197, 178, 0.181);
          color: #ee4d2d;
        }

        .fensu {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-size: 16px;

          .stars {
            display: flex;
            align-items: center;
            margin-right: 10px;

            .el-rate__icon {
              margin-right: -4px;
            }
          }
        }

        .goods-money {
          margin-top: 10px;
          padding: 15px 20px;
          background: #fafafa;

          .price {
            color: #ee4d2d;
            // font-weight: 600;
            font-size: 28px;
            display: flex;
            align-items: center;
          }
        }

        .info-content {
          padding: 0 20px;
          margin-top: 30px;

          .yunfei {
            display: flex;
            font-size: 14px;
            color: #757575;
            padding: 10px 0;

            .text {
              min-width: 110px;
            }

            .el-input-number__decrease,
            .el-input-number__increase {
              background: transparent;
            }
          }

          .guige {
            display: flex;
            font-size: 14px;
            color: #757575;
            padding: 10px 0;

            .text {
              min-width: 110px;
              padding-top: 10px;
            }

            .zhi {
              display: flex;
              flex-wrap: wrap;

              .guge-btn {
                padding: 6px 20px;
                border: 1px solid rgba(0, 0, 0, 0.09);
                border-radius: 2px;
                font-size: 14px;
                margin-bottom: 8px;
                margin-right: 10px;
                cursor: pointer;
                overflow: hidden;
                position: relative;

                .tick {
                  border: 15px solid transparent;
                  border-bottom: 15px solid #ee4d2d;
                  position: absolute;
                  right: -15px;
                  // right: 0;
                  bottom: 0;

                  .tick-icon {
                    position: absolute;
                    left: -10px;
                    top: 5px;
                    color: #fff;
                    font-size: 5px;
                  }
                }
              }

              .guge-btn:hover {
                border-color: #ee4d2d;
                color: #ee4d2d;
              }
            }
          }
        }

        .chengnuo {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.54);
          display: flex;
          align-items: center;
          border-top: 1px solid rgba(0, 0, 0, 0.05);
          padding: 20px 0;

          .zevbuo {
            color: #222;
            margin-right: 20px;
          }
        }
      }
    }

    .store-box {
      padding: 25px;
      background: #fff;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(220, 220, 220, 0.5);
      margin-top: 20px;
      display: flex;

      .biyjq {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
        // line-height: 40px;
        display: flex;
        align-items: center;

        .red {
          color: #ee4d2d;
          margin-left: 10px;
          margin-right: 40px;
        }
      }
    }

    .content-box {
      width: 953px;

      .goods-guge_miaosu {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        margin-top: 15px;
        background: #fff;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(220, 220, 220, 0.5);

        .tyu {
          img {
            max-width: 100%;
          }

          video {
            max-width: 100%;
          }
        }

        .tyu p,
        .typ li {
          white-space: pre-wrap;
          /* 保留空格 */
        }

        .tyu blockquote {
          border-left: 8px solid #d0e5f2;
          padding: 10px 10px;
          margin: 10px 0;
          background-color: #f1f1f1;
        }

        .tyu code {
          font-family: monospace;
          background-color: #eee;
          padding: 3px;
          border-radius: 3px;
        }

        .tyu pre>code {
          display: block;
          padding: 10px;
        }

        .tyu table {
          border-collapse: collapse;
        }

        .tyu td,
        .tyu th {
          border: 1px solid #ccc;
          min-width: 50px;
          height: 20px;
        }

        .tyu th {
          background-color: #f1f1f1;
        }

        .tyu ul,
        .tyu ol {
          padding-left: 20px;
        }

        .tyu input[type="checkbox"] {
          margin-right: 5px;
        }

        .title {
          background: rgba(0, 0, 0, 0.02);
          color: rgba(0, 0, 0, 0.87);
          font-size: 18px;
          padding: 14px;
          margin-bottom: 26px;
        }

        .guge-list {
          padding: 15px;
          font-size: 14px;
          padding-top: 0;
          line-height: 30px;

          .guge-item {
            display: flex;

            .left {
              color: rgba(0, 0, 0, 0.4);
              min-width: 140px;
            }

            .right {}
          }
        }
      }

      .goods-pinjia {
        background: #fff;
        margin-top: 15px;
        background: #fff;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(220, 220, 220, 0.5);
        padding: 25px;

        .pinjia-head {
          background-color: #fffbf8;
          height: 146px;
          border: 1px solid #f9ede5;
          margin-top: 20px;
          padding: 30px;
          display: flex;
          margin-bottom: 20px;

          .el-rate__icon {
            margin-right: -4px;
            font-size: 26px;
          }

          .pinjia-btn {
            min-width: 100px;
            text-align: center;
            font-size: 14px;
            border: 1px solid rgba(0, 0, 0, 0.09);
            height: 32px;
            line-height: 32px;
            margin-right: 10px;
            background: #fff;
            padding: 0 10px;
            color: rgba(0, 0, 0, 0.87);
            cursor: pointer;
          }

          .pinjia-btn_active {
            border-color: #ee4d2d;
            color: #ee4d2d;
          }
        }

        .pinjia-list {

          .el-pagination.is-background .btn-next,
          .el-pagination.is-background .btn-prev,
          .el-pagination.is-background .el-pager li {
            background: transparent;
          }

          .el-pagination.is-background .el-pager li:not(.disabled).active {
            background: #ee4d2d;
          }
        }
      }

      .same {
        margin-top: 40px;

        .title {
          font-size: 16px;
          margin-bottom: 20px;
        }

        .same-list {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    .hot-box {
      margin-top: 15px;
      margin-left: 15px;
      background: #fff;
      height: auto;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(220, 220, 220, 0.5);

      .title {
        font-size: 14px;
        padding: 10px;
        padding-bottom: 0;
        color: rgba(0, 0, 0, 0.4);
        margin-top: 5px;
      }
    }

    .coupons-box {
      margin-top: 15px;
      margin-left: 15px;
      background: #fff;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(220, 220, 220, 0.5);
      padding-bottom: 20px;

      .title {
        font-size: 14px;
        padding: 10px;
        padding-bottom: 0;
        color: rgba(0, 0, 0, 0.4);
        margin-top: 5px;
      }
    }
  }

  .el-dialog__wrapper {
    display: flex;
    align-items: center;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 12px;
      padding-bottom: 30px;
    }

    .el-dialog {
      margin: 0 auto !important;
    }
  }

  .goods-img_pop {
    display: flex;
    justify-content: space-between;

    .pop-left {
      width: 636px;

      .swiper-button-prevs {
        position: absolute;
        top: 378px;
        left: 0;
        width: 40px;
        height: 80px;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;
      }

      .swiper-button-nexts {
        position: absolute;
        top: 378px;
        right: 5px;
        width: 40px;
        height: 80px;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;
      }
    }

    .pop-right {
      width: 296px;
      margin-left: 12px;
      // padding: 40px 0 30px 20px;
      padding-top: 20px;

      .img-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 6px;

        .img-list_item {
          margin: 4px;
          border: 2px solid #fff;
          position: relative;
        }

        .img-active {
          border-color: #ee4d2d;
        }
      }
    }
  }

  video {
    background: #000;
  }
}

.typ {
  border: 3px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
  margin-top: 20px;
  overflow-x: auto;
}
</style>