<template>
  <div class="infoHotGoods">
    <div class="item" @click="toPush(data.id)">
      <el-image
        style="width: 192px; height: 192px"
        :src="data.image"
        fit="fit"
      ></el-image>
      <div
        style="
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          padding-top: 5px;
          margin-bottom: 5px;
        "
      >
        {{ data.title }}
      </div>
      <div style="font-size: 16px; color: #ee4d2d">RM{{ data.price }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "infoHotGoods",
  props: ["data"],
  data() {
    return {};
  },
  methods: {
    toPush() {
      this.$parent.toGoodsInfo(this.$props.data.id);
    },
  },
};
</script>
<style lang="less" scoped>
.infoHotGoods {
  .item {
    width: 230px;
    height: 302px;
    padding: 20px 20px 0;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    cursor: pointer;
  }
}
</style>