<template>
  <div class="CouponsV">
    <div class="folat">
      <div class="asd"></div>
    </div>

    <div class="j-right">
      <div style="font-size: 12px; font-weight: 600; color: #ee4d2d">
        {{
          data.discount_coupon_type == 1
            ? "RM" + data.discount_amount
            : data.discount_amount + "%"
        }}折扣
      </div>
      <div style="font-size: 12px; color: #ee4d2d">
        最低消费RM{{ data.min_consume_amount
        }}{{
          data.max_discount_amount == 0
            ? ""
            : "上限为RM" + data.max_discount_amount
        }}
      </div>
      <div style="margin: 6px 0; transform: scale(0.9)">
        <span class="tags" v-if="data.coupon_type == 2">指定商品</span>
      </div>
      <!-- <div class="seep">
        <div class="tiao"></div>
      </div>
      <div style="font-size: 12px; color: #ee4d2d">
        已使用78%,即将截止：剩下6小时
      </div> -->
      <div style="font-size: 12px; color: #b0b0b0">有效期:{{ jsTime }}</div>
    </div>
    <div class="line"></div>
    <div class="j-left">
      <el-button
        v-if="data.state == 1"
        @click="handleLq()"
        size="mini"
        type="primary"
        style="margin: 0 10px"
        >领取</el-button
      >
      <el-button
        v-else
        @click="handleLq()"
        disabled
        size="mini"
        type="info"
        style="margin-right:10px"
        >已领取</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      circleUrl: "",
      jsTime: 0,
    };
  },
  mounted() {
    let date = new Date(this.$props.data.end_time * 1000);
    this.jsTime =
      date.getMonth() + 1 + "." + date.getDate() + "." + date.getFullYear();
  },
  methods: {
    handleLq() {
      this.$parent.receive(this.$props.data);
      this.$props.data.state=2
      this.$forceUpdate()
    },
  },
};
</script>
<style lang="less" scoped>
.CouponsV {
  width: 212px;
  height: 92px;
  border: 1px solid #fcd5ce;
  box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  border-left: 1px solid rgba(250, 250, 250, 0.01);
  display: flex;
  align-items: center;
  border-radius: 2px;

  padding-left: 20px;
  background: #fff4f4;
  margin: 0 auto;
  margin-top: 10px;
  .folat {
    content: "";
    width: 6px;
    height: 100%;
    position: absolute;
    left: -1px;
    top: 0;
    background: radial-gradient(
      circle at 0 6px,
      transparent 0,
      #fff 5px,
      #fff4f4 0,
      #fcd5ce 6px,
      #fff4f4 0
    );
    background-size: 6px 14px;
    background-repeat: repeat-y;
    .asd {
      background: repeating-linear-gradient(
        #fff4f4,
        #fff4f4 1.9px,
        transparent 0,
        transparent 12px
      );
      background-size: 6px 14px;
      position: absolute;
      left: 0;
      top: -1px;
      height: 100%;
      width: 1px;
    }
  }
  .j-left {
    width: 130px;
    height: 100%;
    text-align: center;
    padding: 30px 0 20px;
  }
  .j-right {
    width: 200px;
    .tags {
      border: 1px solid #ee4d2d;
      padding: 2px;
      font-size: 12px;
      border-radius: 2px;
      color: #ee4d2d;
      margin-left: -10px;
    }
    .seep {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: #e8e8e8;
      margin-bottom: 4px;
      .tiao {
        width: 70%;
        height: 100%;
        background: linear-gradient(to right, #eb1d16, #feac00);
        border-radius: 4px;
      }
    }
  }
  .line {
    height: 92px;
    width: 1px;
    margin-left: 10px;
    background-image: linear-gradient(
      to bottom,
      #eee 0%,
      #eee 50%,
      transparent 0%
    );
    background-size: 1px 8px;
    background-repeat: repeat-y;
  }
}
</style>