<template>
  <div class="sameStoreGoods">
    <div class="item" @click="toPush(data.id)">
      <el-image
        style="width: 148px; height: 148px"
        :src="data.image"
        fit="fit"
      ></el-image>
      <div style="padding: 8px">
        <div
          style="
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            margin-bottom: 4px;
            height: 32px;
          "
        >
          {{ data.title }}
        </div>
        <div style="margin-bottom: 8px">
          <el-tag effect="plain" size="mini">COD</el-tag>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
          "
        >
          <div style="color: #ee4d2d">
            RM
            <span style="font-size: 14px"> {{ data.price }}</span>
          </div>
          <div style="color: rgba(0, 0, 0, 0.54)">售 {{ data.sales }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SameStoreGoods",
  props: ["data"],
  methods: {
    toPush() {
      this.$parent.toGoodsInfo(this.$props.data.id);
    },
  },
};
</script>
<style lang="less" scoped>
.sameStoreGoods {
  padding: 5px 5px 0 5px;
  .item {
    width: 148px;
    background: #fff;
  }
  .item:hover {
    transform: translateY(-2px);
    transition: all 200ms ease;
    z-index: 1;
    cursor: pointer;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
  }
}
</style>